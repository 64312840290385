// Copyright 2020 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.main-content.cluster {
    overflow-y: auto;

    .card {
        flex: 1;
    }

    h1 {
        font-size: 16px;
        font-weight: bold;
        opacity: 0.75;
    }

    .zeros {
        padding: 16px 0;
        min-height: 130px;

        .summary-panel {
            float: left;
            height: 100%;
            margin-right: 20px;
            width: 160px;

            .license {
                font-family: monospace;

                .value {
                    font-weight: bolder;
                }
            }
        }
    }

    .groups {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: 1300px) {
            display: block;
        }

        .group {
            flex: 1;
            min-height: calc(max(22vw, 200px));
            min-width: calc(max(22vw, 200px));
            padding: 20px;

            h1 {
                font-size: 12px;
            }

            .nodes {
                margin-bottom: 24px;
            }

            .node {
                background-color: #fff;
                cursor: pointer;
            }

            .tablets {
                font-family: monospace;
                max-width: 700px;
                .tablet button.move {
                    opacity: 0;
                    border: none;
                    background: transparent;

                    transition: opacity 200ms;
                }

                .tablet:hover button.move,
                .tablet button.move:hover,
                .tablet button.move:focus,
                .tablet button.move:active {
                    opacity: 1;
                }

                .space {
                    display: inline-block;
                    float: right;

                    &.default {
                        color: #ccc;
                    }
                }
            }
        }
    }

    .nodes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .node {
            border: 1px solid #ccc;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 8px 8px 0;
            padding: 8px;
            position: relative;
            white-space: nowrap;

            .health,
            .id {
                margin-right: 4px;
            }

            .addr {
                text-overflow: ellipsis;
                overflow: hidden;
                flex: 1;
            }

            .leader-wrap {
                position: absolute;
                display: inline-block;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                overflow: hidden;

                .leader {
                    $size: 10px;
                    border: $size solid rgb(71, 192, 238);
                    position: absolute;
                    top: -$size;
                    right: -$size;
                    transform: rotate(45deg);
                }
            }

            button.dropdown-toggle {
                position: absolute;
                z-index: 10;
                background-color: transparent;
                display: inline-block;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 0;

                &::after {
                    display: none;
                }
            }

            .id {
                opacity: 0.5;
                display: inline-block;
            }
        }
    }

    .health {
        $size: 8px;
        border-radius: $size / 2;
        display: inline-block;
        height: $size;
        width: $size;
        margin-top: 9px;

        &.unknown {
            background-color: #ccc;
        }

        &.healthy {
            background-color: #28a745;
        }

        &.dead {
            background-color: #dc3545;
        }
    }
}
