// Copyright 2017-2021 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.backups {
    .backups-view {
        flex: 1;
        display: flex;
        flex-direction: column;

        .btn-toolbar {
            margin-bottom: 12px;
        }
    }

    .backups-table {
        flex: 1;
    }
}

.backup-well {
    background-color: #efefef;
    border-radius: 3px;
    margin-bottom: 8px;
    padding: 8px 4px;
}

.form-group.backup-preview {
    background-color: #efefef;
    border-radius: 3px;
    font-family: monospace;
    margin: 0;

    &.large {
        font-size: 1em;
        font-weight: bold;
    }
}
