// Copyright 2017-2021 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Dgraph Community License (the "License"); you
// may not use this file except in compliance with the License. You
// may obtain a copy of the License at
//
//     https://github.com/dgraph-io/ratel/blob/master/LICENSE

.main-content.acl .acl-view {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 0px;

    & > .vertical-panel-layout {
        flex: 1;
    }

    .btn-toolbar {
        padding: 8px 0;
    }

    .panel.first {
        display: flex;
        flex-direction: column;
        .datagrid {
            flex: 1;
        }
    }

    .panel.second {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .panel h3.panel-title {
        font-size: 16px;
    }

    .details-pane-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > * {
            padding-left: 8px;
        }

        .datagrid {
            padding-left: 0;
            flex: 1;
        }
    }
}
